@tailwind base;
@tailwind components;
@tailwind utilities;

/* ************************************************** */
/* Global */
/* ************************************************** */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,300&display=swap');

@font-face {
    font-family: 'Narrabeen Brush';
    src: local('Narrabeen Brush'), url(../fonts/narrabeen/Narrabeen-Regular.otf) format('opentype');
    src: local('Narrabeen Brush'), url(../fonts/narrabeen/Narrabeen-Regular.ttf) format('truetype');
}

body {
    margin: 0;
    font-family: 'Montserrat', 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 0px !important;
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    background-color: #fefefe;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
    @apply border-b;
    @apply border-dashed;
    @apply border-gray-400;
}

a:hover {
    @apply border-solid;
    @apply text-secondary;
    @apply border-secondary;
}

a.nothing {
    @apply border-0;
}

/* ************************************************** */
/* Blocking Loader */
/* ************************************************** */

.blocking-loader {
    z-index: 1300;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    /* background-color: rgba(255, 255, 255, 0.5); // Fallback */
}

/*material table header color change*/
.MTableHeader-header-97 {
    background-color: #ccc !important;
}

/* ************************************************** */
/* Bounce Animation */
/* ************************************************** */

.bounce {
    display: inline-block;
    position: relative;
    -moz-animation: bounce 0.6s infinite linear;
    -o-animation: bounce 0.6s infinite linear;
    -webkit-animation: bounce 0.6s infinite linear;
    animation: bounce 0.6s infinite linear;
    colr: 000;
}

@-webkit-keyframes bounce {
    0% {
        top: 0;
    }

    50% {
        top: -0.1em;
    }

    70% {
        top: -0.2em;
    }

    100% {
        top: 0;
    }
}

@-moz-keyframes bounce {
    0% {
        top: 0;
    }

    50% {
        top: -0.1em;
    }

    70% {
        top: -0.2em;
    }

    100% {
        top: 0;
    }
}

@-o-keyframes bounce {
    0% {
        top: 0;
    }

    50% {
        top: -0.1em;
    }

    70% {
        top: -0.2em;
    }

    100% {
        top: 0;
    }
}

@-ms-keyframes bounce {
    0% {
        top: 0;
    }

    50% {
        top: -0.1em;
    }

    70% {
        top: -0.2em;
    }

    100% {
        top: 0;
    }
}

@keyframes bounce {
    0% {
        top: 0;
    }

    50% {
        top: -0.1em;
    }

    70% {
        top: -0.2em;
    }

    100% {
        top: 0;
    }
}

@media (max-width: 640px) {
    .socialMediaCss {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 640px) {
    .socialIconMediaCss {
        width: 24px;
        height: 24px;
    }
}

input,
select {
    font-size: 100%;
}

.indicator-container::before {
    content: 'More';
    color: #103b58;
    font-family: 'Montserrat', 'sans-serif';
    padding-right: 1%;
}

.headshotMask {
    mask-border: url('../images/headshot-border.png');
}

.mask-bg-img {
    mask-border: url('../images/mask.svg');
    mask-border-outset: 1px;
    -webkit-mask-image: url('../images/mask.svg');
    mask-image: url('../images/mask.svg');
    mask-size: 100% 100%;
}

@media screen and (min-width: 768px) {
    .image-size {
        height: 362px;
        max-width: 90%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

@media screen and (min-width: 63.5em) {
    .swiper-pagination::before {
        content: 'More';
        color: #103b58;
        margin-right: 16px;
        font-family: 'Montserrat', 'sans-serif';
    }

    .swiper-pagination {
        position: absolute;
        left: unset;
    }

    .swiper-pagination-bullets.swiper-pagination-horizontal {
        width: auto;
        left: auto;
        right: 0;
    }
}

.swiper-pagination-bullet {
    --swiper-pagination-bullet-horizontal-gap: 8px;
    width: 11px;
    height: 11px;
    text-align: center;
    line-height: 20px;
    font-size: 0px;
    color: #000;
    opacity: 1;
    background: #d9d9d9;
}

.swiper-pagination-bullet-active-prev,
.swiper-pagination-bullet-active-next {
    transform: scale(0.86) !important;
}

.swiper-pagination-bullet-active-prev-prev,
.swiper-pagination-bullet-active-next-next {
    transform: scale(0.7) !important;
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: #103b58;
    transform: scale(1.2);
    transition: all 250ms ease-out;
}

.custom-tone-border {
    border-radius: 3em;
}

.active-border::before {
    content: '';
    position: absolute;
    left: 1px;
    bottom: -1rem;
    width: 100%;
    height: 2px;
    background-color: #f35b54;
    border-radius: 3px;
    opacity: 1;
}

/*scroll bar style*/
/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #666;
}

.dots-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.dot {
    width: 6px;
    height: 6px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #f35b54;
    animation: bounce 1s infinite ease-in-out both;
}

@keyframes bounce {
    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
        transform: scale(1);
    }
}

.react-tel-input:hover {
    border: 1px solid #103b58;
    outline-color: #103b58;
    border-radius: 4px;
}

/* Horizantal Scrolling*/

.scroll-container {
    overflow: hidden;
    padding: 15px;
}

.breakWord {
    overflow-wrap: break-word;
}
.scroll-content {
    white-space: nowrap;
    animation: scroll 30s linear infinite;
    cursor: pointer;
}

.hyperlink {
    @apply text-blue-600 underline;
}

.hyperlink:visited {
    @apply text-purple-600;
}

@keyframes scroll {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
        transform: translateX(-100%);
    }
}

.MuiOutlinedInput-notchedOutline {
    border: 2px solid #103b58;
}

.sigupBox {
    width: 100px;
    height: 100px;
    animation: moveBox 3s infinite alternate;
    /* Use CSS animation */
}

@keyframes moveBox {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(50px);
    }
}
