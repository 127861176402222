.alic {
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
}
.jodit-wysiwyg li {
    margin-left: 35px;
}

.jodit_link_insert {
    background-color: #ff0000;
}
.jodit-ui-button_variant_primary {
    background-color: #103b58 !important;
    text-transform: uppercase !important;
}

.jodit-add-new-line_after {
    display: none !important;
}
